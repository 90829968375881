import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
        path: '/info',
        name: 'info',
        component: () => import(/* webpackChunkName: "info" */ './views/Info.vue')
    },
    {
      path: '/renew',
      name: 'renew',
      component: () => import(/* webpackChunkName: "info" */ './views/Renew.vue')
    },
    {
      path: '/form',
      name: 'form_for_receipt',
      component: () => import(/* webpackChunkName: "form_for_receipt" */ './views/Form.vue')
    },
    {
      path: '/offline',
      name: 'offline',
      component: () => import(/* webpackChunkName: "offline" */ './views/Offline.vue')
    }
  ]
})
