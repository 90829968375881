<template>
<a :href="button_link" class="card ml-2 mr-2 mb-4" style="max-width: 20rem">
    <div class="card-block">
        <h4 class="card-title">
            {{card_title}}
        </h4>
        <p class="card-text">
            <slot name="description" />
        </p>
    </div>
</a>
</template>

<script>
export default {
    props: {
      card_title: String,
      button_link: String
    }
}
</script>

<style scoped>
@media (min-width: 576px)
.card-group > .card + .card {
    border-left-width: 0px;
}
.card-block {
    margin-left:10px;
    margin-right:10px;
    margin-top:10px;
}
.card-title {
    font-size: 18px;
    font-weight: bold;
}
.card-text {
    font-size: 14px;
    color: black;
}
</style>