<template>
  <div id="app">
    <div class="container">
      <app-nav />
      <router-view />
      <app-footer />
    </div>
  </div>
</template>

<script>
  import AppNav from '@/components/AppNav.vue'
  import AppFooter from '@/components/AppFooter.vue'

  export default {
    name: 'app',
    components:{
      AppNav,
      AppFooter
    }
  }
</script>

<style>
body{
  font-size:14px;
}

@media (min-width: 992px)
.container {
    width: 970px;
}

@media (min-width: 768px)
.container {
    width: 750px;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;

  margin-right: auto;
  margin-left: auto;
  margin-bottom: 80px;
}
</style>
