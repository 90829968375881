<template>
    <footer class="footer none">
        Copyright &copy; <a :href="site_url">R&amp;C IP WORKS, LTD.</a>
    </footer>
</template>

<script>
export default {
    data () {
        return{
            site_url: process.env.VUE_APP_SITE_URL
        }
    }
}
</script>

<style scoped>
    a {
        color:white;
    }
    .footer {
      margin-top: 3em;
      padding: 5px 0;
      text-align: center;
      background-color: #222439;
      color: #fff;
    }
@media print {
    .none {
      display:none;
    }
}
</style>