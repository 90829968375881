<template>
  <div class="home">
    <app-header>メニュー</app-header>
    <app-notice />
    <h1>ライセンスに関するお手続き</h1>

    <b-card-group>
        <link-card-simple card_title="🆕新規購入" button_link='https://rc-ipworks.com/product/fugo/plan/'>
          <p slot="description">
            本ページでは、<b>新たなライセンスキーを購入</b>する手続きができます。 
          </p>
        </link-card-simple>

        <link-card-simple card_title="🔄更新" button_link='/renew'>
          <p slot="description">
            本ページでは、<b>ライセンス期限の更新</b>や、利用者数の追加、プランのアップグレードの手続きができます。<br>
            また、定期契約の解約手続きも行えます。
          </p>
        </link-card-simple>

        <link-card-simple card_title="📃請求書・領収書の発行" button_link='/form'>
        <p slot="description">
          ライセンスご注文後、請求書や、領収書をWEBで発行できます。
        </p>
        </link-card-simple>
    </b-card-group>

    <b-card-group>
      <link-card-simple card_title="ℹライセンスの確認" button_link='/info'>
        <p slot="description">
          本ページでは、ライセンスキーの<b>ライセンス期限</b>や、<b>現在ライセンス認証されているPC</b>を確認できます。
        </p>
      </link-card-simple>
    </b-card-group>

    <h1>FUGOのご利用に関する情報</h1>
    <b-card-group>
        <link-card-simple card_title="📂最新版のダウンロード" button_link='https://rc-ipworks.com/product/fugo/download/'>
          <p slot="description">
            FUGOの最新版、旧バージョンのダウンロードが可能です。最新版の動作が不安定な場合には、旧バージョンをお使いください。
          </p>
        </link-card-simple>

        <link-card-simple card_title="ℹ更新履歴" button_link='https://rc-ipworks.com/product/fugo/releasenote/'>
          <p slot="description">
            FUGOの更新情報を公開しています。バージョンアップによる、新規機能追加・改善点・不具合の修正を解説しています。
          </p>
        </link-card-simple>

        <link-card-simple card_title="☑ オフライン認証" button_link='/offline'>
          <p slot="description">
            本ページでは、FUGOを利用するPCを<b>インターネットに接続していない状態</b>でライセンス認証を行えます。
          </p>
        </link-card-simple>
    </b-card-group>

    <b-card-group>
        <link-card-simple card_title="📄マニュアル" button_link='https://rc-ipworks.com/product/fugo/man/'>
          <p slot="description">
            FUGOの機能、および設定項目（動作カスタマイズ）について、説明を行っております。
          </p>
        </link-card-simple>
    </b-card-group>

    <h1>その他のお問い合わせ・ご要望</h1>
    <ul class=menu>
      <li><a :href='contact_url'>お問い合わせページ</a>からお気軽にお申し付けください。</li>
    </ul>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader' 
import AppNotice from '@/components/AppNotice' 
import LinkCardSimple from '@/components/LinkCardSimple'

export default {
  name: 'home',
  data (){
    return {
      contact_url : process.env.VUE_APP_CONTACT_URL
    }
  },
  components: {
    AppHeader,
    AppNotice,
    LinkCardSimple
  }
}
</script>

<style scoped>

h1 {
  border-left: solid 5px gray;
  font-size: 24px;
  margin-top: 25px;
  text-indent: 15px;
  margin-bottom: 15px;
}

h2{
    color: #1c376e;
    font-size: 1.5rem;
    line-height: 1.3;
    font-weight: 900;
    margin-top: 30px;
    padding: 0.25em 0.5em;
    background: transparent;
    border-left: solid 5px #1c376e;
}

h3 {
  font-size:20px;
  background-color:whitesmoke;
  padding-left:20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

ul.menu {
  margin-top: 20px;
}

ul.menu > li
{
  margin-bottom:4px;
}


img.icon{
    margin: 2px 10px;
}

</style>